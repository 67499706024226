export async function cartAdd(id, quantity, variation) {
  const config = useRuntimeConfig()
  const cart = useCartStore()
  // const auth = useAuthStore()
  const { addToast } = useToasts()

  try {
    const ct = useCookie('ct')
    const headers = {
      'cart-token': ct.value,
      'content-type': 'application/json'
    }
    // if (auth.me) headers['user-id'] = auth.me?.user_id
    cart.useLoading(true)
    const body = { id, quantity }
    if (variation) body.variation = variation
    console.log(JSON.stringify(body, null, 2))
    const x = await fetch(config.public.CMS + '/wp-json/wc/store/cart/add-item', {
      method: 'post',
      headers,
      body: JSON.stringify(body)
    })
    const y = await x.json()
    if (!y.code) {
      cart.updateCart(y)

      cart.useMiniCart(true)
      return {
        quantity: 1
      }
    }
    if (y.code === 'woocommerce_rest_product_partially_out_of_stock') {
      addToast({
        id: +new Date(),
        content: `Ne možeš dodati tu količinu u korpu jer nema dovoljno na zalihama. Dostupno je <strong>${data.value[0].available}</strong>.`,
        error: true
      })
    }
  }
  catch (error) {
    console.error(error)
  }
  finally {
    cart.useLoading(false)
  }
}

export const { format: formatCurrency } = new Intl.NumberFormat('sr-RS', {
  style: 'currency',
  currency: 'RSD',
  minimumFractionDigits: 2
})
